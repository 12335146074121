<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
export default {
  props: {
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
    series: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "column",
          backgroundColor: "transparent",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Social Security Benefit Decline",
        },
        // subtitle: {
        //   text: "Source: ",
        // },
        xAxis: {
          categories: this.categories,
          title: {
            text: null,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Percentage",
          },
        },
        tooltip: {
          headerFormat: "<span>{point.key}</span><table>",
          pointFormat:
            "<tr> " + '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: this.series,
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
